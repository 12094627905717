import { PropsWithChildren, useMemo } from 'react'
import Card, { CardType } from './Card'
import { ReactFC } from 'common/types/ReactFC'
import classNames from 'classnames'
import ExampleResponsiveImage from 'components/ResponsiveImage'
import imageResize from 'project/image-proxy-web'

type ImageCardType = CardType & {
  image: string
  mobileImage?: string
  imageAlt: string
  imageClass?: string
  textAlign?: 'left' | 'center' | 'right'
  verticalAlign?: 'start' | 'end' | 'center'
  gutters?: string
  padding?: string
  imageLeft?: boolean
  mobileImageProps?: {}
  reverseColumnsMobile?: boolean
  reverseColumnsDesktop?: boolean
  classNameForCol1?: string
  classNameForCol2?: string
  priority?: boolean
  roundedBox?: boolean
}

const ImageCard: ReactFC<PropsWithChildren<ImageCardType>> = ({
  children,
  classNameForCol1 = 'col-lg-6',
  classNameForCol2 = 'col-lg-6',
  gutters = '',
  image,
  imageAlt,
  imageClass,
  imageLeft,
  mobileImage,
  mobileImageProps,
  padding = 'md',
  priority,
  reverseColumnsDesktop,
  reverseColumnsMobile,
  roundedBox = true,
  textAlign = 'left',
  verticalAlign = 'start',
  ...rest
}) => {
  const items = useMemo(() => {
    const res = [
      <div
        key={0}
        className={classNames(
          classNameForCol1,
          'd-flex flex-column card__content',
          `padding-${padding}`,
          {
            'align-items-center': textAlign === 'center',
            'align-items-end': textAlign === 'right',
            'justify-content-center': verticalAlign === 'center',
            'justify-content-end': verticalAlign === 'end',
            'order-lg-first order-last': reverseColumnsDesktop,
            'order-lg-last order-first': reverseColumnsMobile,
            'rounded-box': roundedBox,
            'text-center': textAlign === 'center',
          },
        )}
      >
        {children}
      </div>,
      <div
        key={1}
        className={classNames(classNameForCol2, 'd-flex', {
          'justify-content-center align-items-center':
            verticalAlign === 'center',
        })}
      >
        {mobileImage ? (
          <ExampleResponsiveImage
            src={image}
            priority={priority}
            alt={imageAlt}
            mobileSrc={mobileImage!}
            mobileProps={mobileImageProps}
            className={classNames(
              'img-fluid pointer-event-none card__image',
              imageClass,
            )}
          />
        ) : (
          <img
            loading={priority ? 'eager' : 'lazy'}
            alt={imageAlt}
            src={imageResize(image)}
            className={classNames(
              'img-fluid pointer-event-none card__image',
              imageClass,
            )}
          />
        )}
      </div>,
    ]
    return imageLeft ? res.reverse() : res
  }, [
    mobileImage,
    mobileImageProps,
    children,
    imageLeft,
    image,
    imageAlt,
    textAlign,
    verticalAlign,
    padding,
  ])
  return (
    <Card {...rest} roundedBox={roundedBox} padding={'0'}>
      <div className={classNames(gutters, 'row flex-1 position-relative')}>
        {items}
      </div>
    </Card>
  )
}

export default ImageCard
