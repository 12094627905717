import { FC, ImgHTMLAttributes } from 'react'
import classNames from 'classnames'
import useWindowSize from './util/useWindowSize'
import imageResize from 'project/image-proxy-web'

type ResponsiveImageType = ImgHTMLAttributes<any> & {
  src: string | { default: { src: string } }
  mobileSrc: string | { default: { src: string } }
  className?: string
  mobileProps?: { className?: string }
  priority?: boolean
}

const ResponsiveImage: FC<ResponsiveImageType> = ({
  mobileProps = {},
  mobileSrc,
  priority,
  src,
  ...rest
}) => {
  const size = useWindowSize()
  return (
    <>
      {!size.isMdOrLower && (
        <img
          loading={priority ? 'eager' : 'lazy'}
          src={imageResize(src)}
          {...rest}
          className={classNames(rest.className, 'd-lg-block', 'd-none')}
        />
      )}
      {!size.isLgOrHigher && (
        <img
          loading={priority ? 'eager' : 'lazy'}
          {...rest}
          {...mobileProps}
          src={imageResize(mobileSrc)}
          className={classNames(
            rest.className,
            mobileProps?.className,
            'd-lg-none',
            'd-block',
          )}
        />
      )}
    </>
  )
}

export default ResponsiveImage
