import React, { FC } from 'react'
import Strings from 'project/localisation'
import Link from 'next/link'
import Button from 'components/forms/Button'
import HoxtonMixSymbol from 'components/HoxtonMixSymbol'
import TeamCard from 'components/cards/TeamCard'
import HeadingWithSpan from 'components/HeadingWithSpan'
import classNames from 'classnames'
import useWindowSize from 'components/util/useWindowSize'

type GetInTouchSectionType = {
  title?: string
  classesForTitle?: string
  hideLink?: boolean
  className?: string
  teamCardOption?: 1 | 2
}

const GetInTouchSection: FC<GetInTouchSectionType> = ({
  children,
  className = 'mt-xl-17 mt-9 mb-5',
  classesForTitle = 'mt-lg-10 mt-5',
  hideLink,
  teamCardOption = 1,
  title = Strings.experiencedTeam,
}) => {
  const size = useWindowSize()
  return (
    <>
      <div className={classNames('col-md-9 mx-auto text-center', className)}>
        <HeadingWithSpan
          size={3}
          className={classNames('h2 text-semibold', classesForTitle)}
        >
          {title}
        </HeadingWithSpan>
        {!hideLink && (
          <>
            {!size.isMdOrLower && (
              <Link className='a-unstyled' href='/contact-us'>
                <Button
                  size='large'
                  iconRight='chevron-right'
                  iconRightColour='#0071E3'
                  theme='text'
                  className='d-md-block d-none lead mx-auto'
                >
                  {Strings.getInTouch}
                </Button>
              </Link>
            )}
            {size.isMdOrLower && (
              <Link className='a-unstyled' href='/contact-us'>
                <Button
                  theme='secondary'
                  className={classNames(
                    'd-md-none mt-6 px-7 mx-auto',
                    teamCardOption === 1 ? 'mb-8' : 'mb-3',
                  )}
                >
                  {Strings.contactUs}
                </Button>
              </Link>
            )}
          </>
        )}
      </div>
      <TeamCard option={teamCardOption} rounded>
        {children}
      </TeamCard>
    </>
  )
}

export default GetInTouchSection
