import { FC } from 'react'
import classNames from 'classnames' // we need this to make JSX compile

type MultilineType = {
  text?: string
  className?: string
}

const Multiline: FC<MultilineType> = ({ className, text }) => {
  const parts = text?.split(/\n/g)
  return (
    <>
      {parts?.map((v, i) => (
        <>
          <span className={classNames('d-block', className)}>{v}</span>
          {parts!.length > 1 && i + 1 !== parts.length}
        </>
      ))}
    </>
  )
}

export default Multiline
