import * as React from 'react' // we need this to make JSX compile
import { FC } from 'react'
import StarRating from './StarRating'
import { reviewsStrings } from 'common/strings/reviews-strings'
import useWindowSize from './util/useWindowSize'
import imageResize from 'project/image-proxy-web'

type ReviewsSummaryType = {
  textAlign: 'left' | 'center'
  average: number
  totalReviews: number
}

const ReviewsSummary: FC<ReviewsSummaryType> = ({
  average,
  textAlign,
  totalReviews,
}) => {
  const size = useWindowSize()
  return (
    <>
      {size.isLgOrHigher && (
        <>
          <div
            className={`text-${textAlign} d-flex justify-content-${textAlign} mt-2`}
          >
            <StarRating theme='light' className='mx-1' value={5} />
          </div>
          <div className={`text-${textAlign} color-white mt-2 text-large`}>
            {average} {reviewsStrings.average} | {totalReviews}{' '}
            {reviewsStrings.reviews}
          </div>
          <div className={`d-flex justify-content-${textAlign} mb-5`}>
            <img
              loading='lazy'
              src={imageResize(
                require('public/images/ratings/reviews-io-white.svg'),
              )}
              alt={'Reviews IO logo'}
              className='img-fluid d-block mt-2'
            />
          </div>
        </>
      )}
      {!size.isLgOrHigher && (
        <div className={`color-white text-${textAlign} mt-3`}>
          <div
            className={`d-flex justify-content-${textAlign} align-items-center`}
          >
            <p className={'h1 text-semibold mb-0 me-2'}>{4.9}</p>
            <p className={'h3 text-semibold'}>{reviewsStrings.excellent}</p>
          </div>
          <div
            className={`d-flex justify-content-${textAlign} align-items-center mt-n2`}
          >
            <p className={'text-capitalize mb-0'}>{reviewsStrings.average}</p>
            <StarRating theme='light' width={18} height={17} value={5} />
          </div>
          <div className={'max-w-180 mx-auto mt-2 mb-6'}>
            <p className={'text-small-m text-right mb-0'}>
              {reviewsStrings.poweredBy}
            </p>
            <img
              loading='lazy'
              src={imageResize(
                require('public/images/ratings/reviews-io-white.svg'),
              )}
              alt={'Reviews IO logo'}
              className='img-fluid w-100'
            />
          </div>
        </div>
      )}
    </>
  )
}

export default ReviewsSummary
