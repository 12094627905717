import * as React from 'react' // we need this to make JSX compile
import { FC, useState } from 'react'
import { ReviewsAPIResponse, TReviews } from 'common/cms-types'
import { reviewsStrings } from 'common/strings/reviews-strings'
import StarRating from 'components/StarRating'
import Button from 'components/forms/Button'
import Card from 'components/cards/base/Card'
import Heading from 'components/Heading'
import { Constants, Utils } from 'common/utils'
import fetch from 'node-fetch'
import { ProductJsonLd } from 'next-seo'
import Icon from 'components/Icon'
import ReviewsSummary from 'components/ReviewsSummary'
import { GradientType } from 'common/types/color-type'

type ReviewsSectionType = {
  data: TReviews
  theme?: GradientType
}

const ReviewsSection: FC<ReviewsSectionType> = ({
  data,
  theme = 'gradient-blue',
}) => {
  const [reviews, setReviews] = useState(data.reviews)
  const [isLoading, setIsLoading] = useState(false)
  const [url, setUrl] = useState(data.url)
  const hasMore = reviews.length < data.numberOfReviews
  const getMore = () => {
    if (isLoading) {
      return
    }
    const [_url, _params] = url.split('?')
    const params = Utils.fromParam(_params)
    params.page = `${parseInt(params.page) + 1}`
    setIsLoading(true)
    const newUrl = `${_url}?${Utils.toParam(params)}`
    setUrl(newUrl)
    fetch(newUrl)
      .then((res) => res.json())
      .then((res: ReviewsAPIResponse) => {
        setReviews(reviews.concat(Utils.parseReviews(res.reviews)))
        setIsLoading(false)
      })
  }
  return (
    <Card padding='0' theme={theme} className='reviews-section'>
      <div className='container px-xl-7'>
        <Heading
          size={2}
          className='text-light text-semibold text-center mt-lg-14 pt-lg-4 mt-5 pt-3'
        >
          {reviewsStrings.whatPeopleAreSaying}
        </Heading>
        <ReviewsSummary
          textAlign={'center'}
          average={data.average}
          totalReviews={data.numberOfReviews}
        />

        <div className='grid-container'>
          {reviews.map((review, i) => (
            <div className='grid-item' key={i}>
              <Card
                theme='white'
                className={'rounded-box'}
                padding='md'
                key={i}
              >
                <Heading className='text-semibold lead-large mb-0' size={4}>
                  {review.name}
                </Heading>
                <StarRating width={18} height={17} value={review.rating} />
                <div className='flex-row align-items-center mt-2'>
                  <Icon name='tick' className='me-2' />
                  <span className='text-semibold text-small'>
                    {reviewsStrings.verifiedBuyer}
                  </span>
                </div>
                <div
                  className='mt-2 text-medium text-medium--lt1'
                  dangerouslySetInnerHTML={{ __html: review.content }}
                />
              </Card>
            </div>
          ))}
        </div>
        {hasMore && (
          <div className='d-flex justify-content-center mt-10'>
            <Button theme='secondary' disabled={isLoading} onClick={getMore}>
              {reviewsStrings.showMore}
            </Button>
          </div>
        )}
      </div>
      <ProductJsonLd
        brand={Constants.companyInformation.name}
        images={[
          `${process.env.SITE_URL}/images/hm-product-sm.png`,
          `${process.env.SITE_URL}/images/hm-product-md.png`,
          `${process.env.SITE_URL}/images/hm-product-lg.png`,
        ]}
        productName='Virtual Office Product Reviews'
        description='View our London Virtual Office plans; a prime London business address from 49p per day with Same-day setup and NO Postage Fees.'
        sku={`virtual-office-collect-monthly-15;virtual-office-forward-monthly-25;virtual-office-scan-monthly-35;live-receptionist;virtual-office-phone-monthly-699`}
        aggregateRating={{
          bestRating: data.best,
          ratingCount: data.numberOfReviews,
          ratingValue: data.average,
          reviewCount: data.numberOfReviews,
          worstRating: data.worst,
        }}
        reviews={reviews.slice(0, 5).map((v) => ({
          author: v.name,
          reviewBody: v.content,
          reviewRating: {
            ratingValue: `${v.rating}`,
          },
        }))}
      />
    </Card>
  )
}

export default ReviewsSection
