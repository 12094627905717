import React, { FC } from 'react'
import Card from './base/Card'
import ExampleResponsiveImage from 'components/ResponsiveImage'
import classNames from 'classnames'
import imageResize from 'project/image-proxy-web'

type TeamCardType = {
  option?: 1 | 2
  rounded?: boolean
}

const TeamCard: FC<TeamCardType> = ({
  children,
  option = 1,
  rounded = false,
}) => {
  return (
    <Card
      className={classNames(
        'team-card mb-lg-17 mx-md-n12px',
        option === 1
          ? 'mt-lg-20 mt-md-14 mt-7 pb-md-0 pb-30'
          : 'mt-xxl-48 mt-lg-36 mt-md-24 mt-7 pb-md-7 pb-4',
        rounded ? 'rounded-box' : '',
      )}
    >
      <ExampleResponsiveImage
        className={classNames(
          `img-fluid pointer-event-none team-card__image-${option}`,
          option === 2 ? 'img-mobile-fullwidth6' : '',
        )}
        alt={'Hoxton Mix Team'}
        src={imageResize(
          require('public/images/homepage-hoxton-mix-team-desktop.png'),
        )}
        mobileSrc={
          option === 1
            ? imageResize(
                require('public/images/homepage-hoxton-mix-team-mobile.png'),
              )
            : imageResize(
                require('public/images/homepage-hoxton-mix-team-desktop.png'),
              )
        }
      />
      {children}
    </Card>
  )
}

export default TeamCard
